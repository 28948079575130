import * as React from "react"

import Layout from "../components/layout"
import Layouttraining from "../components/layouttraining"
import Seo from "../components/seo"

const GroupTrainings = () => {
  let title = "Csoportos edzések felnőtteknek"
  let subTitle = "Dinamizmus, jó hangulat"
  let places = [
    {
      place: "ABS Sportcentrum - Budaörs",
      date: "H-Sz-P de. 7-8 | K-Cs du. 5-6",
    },
    {
      place: "Biafitness Sportcentrum - Biatorbágy",
      date: "Sz du. 6-7",
    },
  ]
  let description =
    "Szereted a dinamikus, már-már a határaidat feszegető edzéseket? Garantáltan kifárasztalak! Akár fogyni szeretnél, akár tartani a súlyodat, formálni magad, vagy erősödni, nálam megtalálod mindegyik lehetőséget. Kipróbálhatod magad az alakformáló, step, gymstick és spining edzéseimen."
  let imageSrc = "group"

  return (
    <Layout>
      <Seo title={title} />
      <Layouttraining
        title={title}
        subTitle={subTitle}
        description={description}
        places={places}
        imageSrc={imageSrc}
      />
    </Layout>
  )
}

export default GroupTrainings
